import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Terminal, TerminalDetail, TerminalState } from '@shared/types';

const initialState: TerminalState = {
    terminal: undefined,
    terminalDetail: undefined,
    updatingTerminalDetail: false,
    productGroups: undefined,
    modifyingTerminalProducts: false,
    email: undefined,
};

export const terminalsSlice = createSlice({
    name: 'terminals',
    initialState,
    reducers: {
        /** Update the selected terminal on the Terminals page */
        updateTerminal: (state, { payload }: PayloadAction<Terminal | undefined>) => ({
            ...state,
            terminal: payload,
        }),
        /** Update the terminal detail on the Terminals page */
        updateTerminalDetail: (state, { payload }: PayloadAction<TerminalDetail | undefined>) => ({
            ...state,
            terminalDetail: payload,
        }),
        /** Update the terminal detail on the Terminals page */
        updateProductGroups: (state, { payload }: PayloadAction<string[] | undefined>) => ({
            ...state,
            productGroups: payload,
        }),
        /** Update modifying flag */
        updateModifyingTerminalProducts: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            modifyingTerminalProducts: payload,
        }),
        updateEmail: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            email: payload,
        }),
    },
});
