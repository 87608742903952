export const setHoursToZero = (date: string | Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const dateFix = (date: string | Date): Date => {
  const newEndDate = new Date(date);
  newEndDate.setHours(24, 59, 59, 999);
  return newEndDate;
};

export const convertToNoon = (date: string): Date => {
  const newDate = date.split('T')[0] + 'T12:00:00.000Z';
  return new Date(newDate);
};
