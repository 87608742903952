import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';
import { ProductByTerminal, ProductWithTerminals, Recipe, TerminalProduct } from '@shared/types';

export const buildRecipeProductEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
  getProductsByTerminal: builder.query<ProductByTerminal[], { terminal: string }>({
    query: (params) => ({
      url: `${resources.RECIPEPRODUCT}`,
      params: params,
    }),
  }),
});
