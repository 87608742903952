import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';
import { ComponentBody, Terminal, TerminalProduct } from '@shared/types';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildTerminalEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getTerminals: builder.query<Terminal[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.TERMINAL}`,
            params: params,
        }),
        providesTags: [],
    }),
    terminal_getProducts: builder.query<TerminalProduct[], { terminal: string }>({
        query: (params) => ({
            url: `${resources.RECIPEPRODUCT}`,
            params: params,
        }),
        providesTags: ['TerminalProducts'],
    }),
    terminal_saveProducts: builder.mutation<AnyObject, { terminal: number; product: number; email: string }>({
        query: (payload) => ({
            url: `${resources.RECIPEPRODUCT}`,
            method: 'POST',
            body: payload,
        }),
        invalidatesTags: ['TerminalProducts'], //Invalidate list so getEcho will fire again. Currently these assume you return an array of items
    }),
    terminal_removeProducts: builder.mutation<AnyObject, { terminal: string; product: string }>({
        query: (payload) => ({
            url: `${resources.RECIPEPRODUCT}`,
            method: 'DELETE',
            body: payload,
        }),
        invalidatesTags: ['TerminalProducts'], //Invalidate list so getEcho will fire again. Currently these assume you return an array of items
    }),
});
