import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intermediate, Terminal, TerminalDetail } from '@shared/types';

export type IntermediateTerminal = {
  terminal: string;
  number: number;
  type: string;
  isActive: boolean;
  startDate: string;
  endDate: string;
  producedAtTerminal: string;
  producedAtTerminalNumber: number;
  terminalNumber: number;
  uuid: string;
};

export type IntermediateProductsToModify = {
  name: string;
  number: number;
  terminals: IntermediateTerminal[];
};

export type IntermediateState = {
  intermediateSelectedType?: IntermediateSelectionType;
  intermediatesProducedAt?: Intermediate[];
  intermediatesHasInventoryOf?: Intermediate[];
  intermediatesProjectedUse?: Intermediate[];
  asOfDate?: string;
  terminalDetail?: TerminalDetail;
  updatingTerminalDetail: boolean;
  productsToModify: IntermediateProductsToModify[];
  productGroups: string[];
  productGroupOptions: string[];
  intermediateIsFetching: boolean;
  modifyingTerminalProducts: boolean;
  terminalOptions: any[];
  terminal?: Terminal;
  isOrdersAdmin: boolean;
  isBlendsAdmin: boolean;
  email?: string;
};
export type IntermediateRelationship = 'UNASSIGNED' | 'PRODUCED_AT' | 'HAS_INVENTORY_OF';
export type IntermediateSelectionType = {
  id: number;
  value: IntermediateRelationship;
};

const initialState: IntermediateState = {
  productsToModify: [],
  productGroups: [],
  productGroupOptions: ['None'],
  asOfDate: undefined,
  intermediateSelectedType: undefined,
  terminalDetail: undefined,
  updatingTerminalDetail: false,
  intermediateIsFetching: false,
  modifyingTerminalProducts: false,
  terminalOptions: [],
  terminal: undefined,
  isOrdersAdmin: false,
  isBlendsAdmin: false,
  email: undefined,
};

export const intermediatesSlice = createSlice({
  name: 'intermediates',
  initialState,
  reducers: {
    updateProductToModify: (state, { payload }: PayloadAction<IntermediateProductsToModify[]>) => ({
      ...state,
      productsToModify: payload,
    }),
    updateIntermediateSelectedType: (state, { payload }: PayloadAction<IntermediateSelectionType | undefined>) => ({
      ...state,
      intermediateSelectedType: payload,
    }),
    /** Update the selected terminal on the Terminals page */
    updateIntermediatesHasInventoryOf: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
      ...state,
      intermediatesHasInventoryOf: payload,
    }),
    /** Update the selected terminal on the Terminals page */
    updateIntermediatesProjectedUse: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
      ...state,
      intermediatesProjectedUse: payload,
    }),
    /** Update As Of Date */
    updateAsOfDate: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      asOfDate: payload,
    }),
    /** Update Product group */
    updateProductGroups: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      productGroups: payload,
    }),
    /** Update Product Group Options */
    updateProductGroupOptions: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      productGroupOptions: payload,
    }),
    /** Is Fetching */
    updateIsFetching: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      intermediateIsFetching: payload,
    }),
    updateModifyingTerminalProducts: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      modifyingTerminalProducts: payload,
    }),
    /**Update Auth */
    updateIsOrdersAdmin: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOrdersAdmin: payload,
    }),
    /**Update Auth */
    updateIsBlendsAdmin: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isBlendsAdmin: payload,
    }),
    /** Update Email */
    updateEmail: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      email: payload,
    }),
    updateTerminalOptions: (
      state,
      { payload }: PayloadAction<{ uuid: string; name: string; abbreviation: string; number: number }[]>
    ) => ({
      ...state,
      terminalOptions: payload,
    }),
    updateTerminal: (state, { payload }: PayloadAction<Terminal | undefined>) => ({
      ...state,
      terminal: payload,
    }),
    updateTerminalDetail: (state, { payload }: PayloadAction<TerminalDetail | undefined>) => ({
      ...state,
      terminalDetail: payload,
    }),
  },
});
