import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { resources } from '@shared/constants';
import { DateBody } from '@shared/types';
import { TagType } from '../tags';

export const buildDateEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
  saveProducesDates: builder.mutation<void, DateBody>({
    query: (payload) => ({
      url: `${resources.PRODUCESDATE}`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: [],
  }),
  saveProjectedProducesDates: builder.mutation<void, DateBody>({
    query: (payload) => ({
      url: `${resources.PROJECTEDPRODUCESDATE}`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: [],
  }),
});
