import { Terminal, TerminalDetail } from './terminal.js';

export type Intermediate = {
  uuid: string;
  name: string;
  abbreviation: string;
  number: number;
  startDate: string;
  endDate: string;
  terminal: string;
  isActive: boolean;
  group?: string;
  type?: string;
};

export type IntermediateProductHasInventoryOf = Intermediate & {
  producedAtTerminal: string;
};

export type GetIntermediatesProducedAt = {
  terminal: string;
};
export type GetIntermediatesWithTerminals = {
  asOfDate: string;
};
export type GetIntermediateInventoryOf = GetIntermediatesProducedAt & GetIntermediatesWithTerminals;

export interface SaveIntermediateProduct {
  terminal: string;
  product: string;
  isActive: boolean;
  email: string;
}

export interface EditIntermediateProducedAt {
  uuid: string;
  terminal: string;
  email: string;
}

export interface SaveIntermediateProducedAt {
  product: string;
  terminal: string;
  email: string;
}

export interface EditIntermediateInventoryOf {
  uuid: string;
  terminal: string;
  email: string;
  startDate: string;
  endDate: string;
}

export interface SaveIntermediateInventoryOf {
  terminal: string;
  product: string;
  producedAtTerminal: string;
  startDate: string;
  endDate: string;
  email: string;
}

export enum IntermediateType {
  PRODUCED_AT = 'PRODUCED_AT',
  HAS_INVENTORY_OF = 'HAS_INVENTORY_OF',
}
