import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { resources } from '@shared/constants';
import {
  EditIntermediateInventoryOf,
  EditIntermediateProducedAt,
  GetIntermediateInventoryOf,
  GetIntermediatesProducedAt,
  GetIntermediatesWithTerminals,
  IntermediateInventory,
  IntermediateProducedAt,
  IntermediateWithTerminals,
  SaveIntermediateInventoryOf,
  SaveIntermediateProducedAt,
  SaveIntermediateProduct,
} from '@shared/types';
import { TagType } from '../tags';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildIntermediateEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
  getIntermediatesWithTerminals: builder.query< IntermediateWithTerminals[], GetIntermediatesWithTerminals>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      params: params,
    }),
    providesTags: [],
  }),
  getIntermediatesProducedAt: builder.query<IntermediateProducedAt[], GetIntermediatesProducedAt>({
    query: (params) => ({
      url: `${resources.INTERMEDIATEPRODUCEDAT}`,
      params: params,
    }),
    providesTags: ['GetIntermediates'],
  }),
  getIntermediatesInventoryOf: builder.query<IntermediateInventory[], GetIntermediateInventoryOf>({
    query: (params) => ({
      url: `${resources.INTERMEDIEATEINVENTORYOF}`,
      params: params,
    }),
    providesTags: ['GetIntermediates'],
  }),
  saveIntermediatesProduct: builder.mutation<any, SaveIntermediateProduct>({
    query: (params) => ({
      url: `${resources.INTERMEDIATE}`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: ['GetIntermediates'],
  }),
  saveIntermediatesProducedAt: builder.mutation<any, SaveIntermediateProducedAt>({
    query: (params) => ({
      url: `${resources.INTERMEDIATEPRODUCEDAT}`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: ['GetIntermediates'],
  }),
  saveIntermediatesInventoryOf: builder.mutation<any, SaveIntermediateInventoryOf>({
    query: (params) => ({
      url: `${resources.INTERMEDIEATEINVENTORYOF}`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: ['GetIntermediates'],
  }),
  editIntermediatesProducedAt: builder.mutation<any, EditIntermediateProducedAt>({
    query: (params) => ({
      url: `${resources.EDITINTERMEDIATEPRODUCEDAT}`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: ['GetIntermediates'],
  }),
  editIntermediatesInventoryOf: builder.mutation<any, EditIntermediateInventoryOf>({
    query: (params) => ({
      url: `${resources.EDITINTERMEDIEATEINVENTORYOF}`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: ['GetIntermediates'],
  }),
});
