import { api } from '@api';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSlice } from '@redux-hooks';
import { convertToNoon, dateFix, setHoursToZero } from '@shared/common/util';
import { IntermediateProductHasInventoryOf, IntermediateType } from '@shared/types';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  dialogRow: IntermediateProductHasInventoryOf;
  setOpen: (value: boolean) => void;
  open: boolean;
}

const options = [IntermediateType.PRODUCED_AT, IntermediateType.HAS_INVENTORY_OF];
export const EditRelationshipDialogBox: FC<Props> = (props: Props) => {

  /* State */
  const { dialogRow, setOpen, open } = props;

  const [editIntermediatesInventoryOf, {data: editInventoryOfData, isLoading: isFetchingInventoryOf}] =
    api.useEditIntermediatesInventoryOfMutation();

  /* Slices   */
  const [intermediateState, intermediateActions] = useSlice('intermediates');

  /* State */
  const [startDate, setStartDate] = useState<Date>(setHoursToZero(new Date()));
  const [endDate, setEndDate] = useState<Date>(dateFix(new Date('2099-01')));
  const [terminal, setTerminal] = useState<{ id: number; name: string } | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string>(dialogRow.type || '');
  const [producedAtTerminals, setProducedAtTerminals] = useState<{ id: number; name: string }[]>([]);


  useEffect(() => {
    if (dialogRow.type === IntermediateType.HAS_INVENTORY_OF) {
      setStartDate(convertToNoon(dialogRow.startDate));
      setEndDate(convertToNoon(dialogRow.endDate));
      setSelectedOptions(dialogRow.type);
      setProducedAtTerminals([{ id: +dialogRow.producedAtTerminal, name: dialogRow.producedAtTerminal }]);
      setTerminal({ id: +dialogRow.producedAtTerminal, name: dialogRow.producedAtTerminal });
    }
    if (!dialogRow.type) {
      setStartDate(setHoursToZero(new Date()));
      setEndDate(dateFix(new Date('2099-01')));
    }
  }, [dialogRow]);

  useEffect(() => { 
    intermediateActions.updateIsFetching(isFetchingInventoryOf)
  } ,[isFetchingInventoryOf]);

  /* Functions */
  const handleSave = () => {
    if (selectedOptions === IntermediateType.HAS_INVENTORY_OF && intermediateState.terminal && startDate && endDate) {
      editIntermediatesInventoryOf({
        uuid: dialogRow.uuid,
        terminal: dialogRow.number.toString(),
        startDate: new Date(startDate).toISOString().split('T')[0], //datesToIsoString(startDate),
        endDate: new Date(endDate).toISOString().split('T')[0], //datesToIsoString(endDate),
        email: intermediateState.email || '',
      }).then(() => { intermediateActions.updateIsFetching(false); });
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setStartDate(setHoursToZero(new Date()));
    const newEndDate1 = new Date('2099-01');
    newEndDate1.setHours(24, 59, 59, 999);
    setEndDate(newEndDate1);
    setSelectedOptions('');
    setProducedAtTerminals([]);
  };

  return (
    <LocalizationProvider key="relationship-date" dateAdapter={AdapterDateFns}>
      <Dialog key="relationship-dialog" open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
        <DialogTitle key="relationship-dialog">
          <Typography component="div" style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>
            Edit Relationship
          </Typography>
          <Typography style={{ fontFamily: 'Times New Roman', fontSize: '18px', fontWeight: 'normal' }}>
            {dialogRow && dialogRow.name}
          </Typography>
          <Typography style={{ fontFamily: 'Courier New', fontSize: '14px', fontWeight: 'lighter' }}>
            {intermediateState.terminal && intermediateState.terminal.name}
          </Typography>
        </DialogTitle>
        <Autocomplete
          id="tags-standard"
          options={options}
          disabled
          onChange={(e, v) => {
            const changeTo = v as string;
            setSelectedOptions(changeTo);
          }}
          renderInput={(params) => (
            <TextField {...params} required label="Select Type" variant="outlined" size="small" />
          )}
          value={selectedOptions}
          sx={{ m: 1 }}
        />
        {selectedOptions === IntermediateType.PRODUCED_AT ? (
          <FormControlLabel
            control={<Switch defaultChecked />}
            label={`Was this product produced at ${intermediateState.terminal?.name || ''}?`}
            sx={{ m: 1 }}
          />
        ) : (
          <> </>
        )}
        {selectedOptions === IntermediateType.HAS_INVENTORY_OF || selectedOptions === 'PROJECTED_USE' ? (
          <>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                if (newValue)
                setStartDate(setHoursToZero(newValue));
              }}
              sx={{ m: 1 }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => {
                if (newValue)
                setEndDate(setHoursToZero(newValue));
              }}
              sx={{ m: 1 }}
            />
            <Autocomplete
              id="terminal-select"
              onChange={(e, v) => {
                setTerminal(v);
              }}
              options={producedAtTerminals}
              disabled
              getOptionLabel={(option: { id: number; name: string }) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Select Terminal Where It's Produced"
                  variant="outlined"
                  size="small"
                />
              )}
              value={terminal}
              sx={{ m: 1 }}
            />
          </>
        ) : (
          null
        )}
        <DialogActions>
          <Button
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
